import { Link } from "react-router-dom";
import { navigationLink } from "../../router/routeConfiguration";
import { ROUTES } from "../../router/route-constant";

const Header = () => {
    return (
        <header className='main-header'>
            <div className='header-sticky'>
                <nav className='navbar navbar-expand-lg'>
                    <div className='container'>
                        <a className='navbar-brand' href='/'>
                            <img src={'./images/logo.svg'} alt='Logo' />
                        </a>
                        <div className='collapse navbar-collapse main-menu'>
                            <div className='nav-menu-wrapper'>
                                <ul className='navbar-nav mr-auto' id='menu'>
                                    {navigationLink.map((value) => (
                                        <li key={value.route} className='nav-item'>
                                            <Link className='nav-link' to={value.route}>
                                                {value.name}
                                            </Link>
                                        </li>
                                    ))}
                                    <li className='nav-item highlighted-menu'>
                                        <Link className='nav-link' to={ROUTES.CONTACT}>
                                            free consultation
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className='header-btn d-inline-flex'>
                                <Link to='contact-us' className='btn-default'>
                                    free consultation
                                </Link>
                            </div>
                        </div>
                        <div className='navbar-toggle'></div>
                    </div>
                </nav>
                <div className='responsive-menu'></div>
            </div>
        </header>
    );
};

export default Header;
