import React from "react";
import { RouteConfig } from "./routeConfiguration";
import ScrollToTop from "./ScrollToTop";

export const PublicRoute = ({ Component }: RouteConfig) => {
    return (
        <React.Fragment>
            <Component />
            <ScrollToTop />
        </React.Fragment>
    );
};
