import Faq from "../modules/Faq";
import Home from "../modules/Home";
import AboutPage from "../modules/About";
import { ROUTES } from "./route-constant";
import ServicesPage from "../modules/Services";
import ContactUsPage from "../modules/ContactUs";

export type RouteConfig = {
  id: number;
  path: string;
  Component: React.FunctionComponent;
  isPublic?: boolean;
  isPrivate?: boolean;
};

export const routerConfiguration: RouteConfig[] = [
  {
    id: 1,
    path: ROUTES.HOME,
    Component: Home,
    isPublic: true,
  },
  {
    id: 2,
    path: ROUTES.ABOUT,
    Component: AboutPage,
    isPublic: true,
  },
  {
    id: 3,
    path: ROUTES.SERVICES,
    Component: ServicesPage,
    isPublic: true,
  },
  {
    id: 4,
    path: ROUTES.CONTACT,
    Component: ContactUsPage,
    isPublic: true,
  },
  {
    id: 5,
    path: ROUTES.FAQ,
    Component: Faq,
    isPublic: true,
  },
];

export const navigationLink = [
  {
    name: "home",
    route: ROUTES.HOME,
  },
  {
    name: "about us",
    route: ROUTES.ABOUT,
  },
  {
    name: "services",
    route: ROUTES.SERVICES,
  },
  {
    name: "faq's",
    route: ROUTES.FAQ,
  },
];
