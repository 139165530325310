import { useState } from "react";
import emailjs from '@emailjs/browser';

class initialValues {
    email: string = "";
    subject: string = "";
    message: string = "";
    lastName: string = "";
    firstName: string = "";
    phoneNumber: string = "";
}

export const ContactForm = () => {
    const [formState, setFormState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
    });

    const updateField = (key: any, value: string) => {
        setFormState((oldState) => ({
            ...oldState,
            ...{ [key]: value }
        }))
    }

    const onSubmit = (event: any) => {
        event?.preventDefault();
        const EMAIL_SERVICE_KEY = process.env.REACT_APP_EMAIL_SERVICE_KEY || "";
        const TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID || "";
        const PUBLIC_ID = process.env.REACT_APP_EMAIL_PUBLIC_KEY || "";


        emailjs.send(EMAIL_SERVICE_KEY, TEMPLATE_ID, formState, {
            publicKey: PUBLIC_ID,
        })
            .then(
                () => {
                    submitMSG(true, "Message Sent Successfully!");
                    var contactForm: any = document.getElementById("contactForm")
                    contactForm?.reset();
                    setFormState({ ...new initialValues() });
                },
                () => {
                    submitMSG(false, "Something went wrong, Please try again to email us directly!");
                },
            );
    }

    const submitMSG = (valid: boolean, msg: string) => {
        if (valid) {
            var msgClasses = "h3 text-success";
        } else {
            var msgClasses = "h3 text-danger";
        }
        const msgSubmitElem = document.getElementById("msgSubmit");
        if (msgSubmitElem) {
            msgSubmitElem.classList.remove();
            msgSubmitElem.className = msgClasses;
            msgSubmitElem.textContent = msg;
        }
    }

    return (
        <div className='contact-form'>
            <form id='contactForm'>
                <div className='row'>
                    <div className='form-group col-md-6 mb-4'>
                        <input
                            type='text'
                            name='fname'
                            className='form-control'
                            id='fname'
                            placeholder='first name'
                            required
                            value={formState.firstName}
                            onChange={({ target }) => updateField('firstName', target.value)}
                        />
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='form-group col-md-6 mb-4'>
                        <input
                            type='text'
                            name='lname'
                            className='form-control'
                            id='lname'
                            placeholder='last name'
                            required
                            value={formState.lastName}
                            onChange={({ target }) => updateField('lastName', target.value)}
                        />
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='form-group col-md-6 mb-4'>
                        <input
                            type='text'
                            name='phone'
                            className='form-control'
                            id='phone'
                            placeholder='Phone'
                            required
                            value={formState.phoneNumber}
                            onChange={({ target }) => updateField('phoneNumber', target.value)}
                        />
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='form-group col-md-6 mb-4'>
                        <input
                            type='email'
                            name='email'
                            className='form-control'
                            id='email'
                            placeholder='email'
                            required
                            value={formState.email}
                            onChange={({ target }) => updateField('email', target.value)}
                        />
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='form-group col-md-12 mb-4'>
                        <input
                            type='text'
                            name='subject'
                            className='form-control'
                            id='subject'
                            placeholder='subjects'
                            required
                            value={formState.subject}
                            onChange={({ target }) => updateField('subject', target.value)}
                        />
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='form-group col-md-12 mb-4'>
                        <textarea
                            name='msg'
                            className='form-control'
                            id='msg'
                            rows={7}
                            placeholder='message'
                            required
                            value={formState.message}
                            onChange={({ target }) => updateField('message', target.value)}
                        ></textarea>
                        <div className='help-block with-errors'></div>
                    </div>

                    <div className='col-md-12'>
                        <button type='submit' className='btn-default' onClick={onSubmit}>
                            send a message
                        </button>
                        <div id='msgSubmit' className='h3 text-left hidden'></div>
                    </div>
                </div>
            </form>
        </div>
    );
};
