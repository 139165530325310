import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactDOM, { hydrateRoot } from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
    hydrateRoot(rootElement, <App />);
} else {
    root.render(<App />);
}

reportWebVitals();
