import { Link } from "react-router-dom";
import "./services.css";
import { ROUTES } from "../../router/route-constant";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";

export const ServiceHeading = () => {
    return (
        <div>
            <div className='row section-row align-items-center'>
                <div className='col-lg-7 col-md-7'>
                    <div className='section-title'>
                        <h3 className='wow fadeInUp'>our services</h3>
                        <h2 className='text-anime-style-3'>What we can offer today</h2>
                    </div>
                </div>

                <div className='col-lg-5 col-md-5'>
                    <div className='section-btn'>
                        <Link
                        onClick={() =>  analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_VIEW_ALL_SERVICE_CLICK,{content_type : 'Our Services',})}
                            to={ROUTES.SERVICES}
                            className='btn-default wow fadeInUp'
                            data-wow-delay='0.25s'>
                            view all services
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
