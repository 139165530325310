/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { LinkItem, ROUTES } from "../../router/route-constant";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";

const PAGES: LinkItem[] = [
    { label: "Home", link: ROUTES.HOME },
    { label: "About Us", link: ROUTES.ABOUT },
    { label: "Services", link: ROUTES.SERVICES },
    { label: "Contact Us", link: ROUTES.CONTACT },
];
const SERVICES: LinkItem[] = [
    { label: "mobile app development", link: ROUTES.SERVICES },
    { label: "web development", link: ROUTES.SERVICES },
    { label: "digital marketing", link: ROUTES.SERVICES },
    { label: "graphic design", link: ROUTES.SERVICES },
    { label: "artificial intelligence & machine learning", link: ROUTES.SERVICES },
    { label: "generative ai and chatbots", link: ROUTES.SERVICES },
];

const CONNECT: LinkItem[] = [
    { label: "Linkedin", link: "https://linkedin.com/company/codingworkx/" },
    { label: "facebook", link: "https://www.facebook.com/people/Codingworkx/61561113533536/" },
    { label: "instagram", link: "https://www.instagram.com/codingworkx?igsh=bWF6ZXBmNzhwdWph" },
];
const Footer = () => {
    return (
        <div>
            <footer className='main-footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='mega-footer'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='footer-about'>
                                            <figure>
                                                <img src='images/logo.svg' alt='' />
                                            </figure>
                                            <p>CodingWorkX - Your Trusted Business Partner</p>
                                            <ul>
                                                <li>
                                                    <a onClick={() => {
                                                        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_EMAIL_CLICK, { content_type: 'connect@codingworkx.com' })
                                                    }} href='#'>connect@codingworkx.com</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => {
                                                        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_PHONE_CLICK, { content_type: '(+91) 894 199 9257' })
                                                    }} href='#'>(+91) 894 199 9257</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-lg-2 col-md-4'>
                                        <div className='footer-links'>
                                            <h2>pages</h2>
                                            <ul>
                                                {PAGES.map((val, i) => (
                                                    <li key={`pages-${i}`}>
                                                        <Link onClick={() => {
                                                            analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_PAGE_CLICK, { content_type: val.label })
                                                        }} to={val.link}>{val.label}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-lg-2 col-md-4'>
                                        <div className='footer-links'>
                                            <h2>Connect</h2>
                                            <ul>
                                                {CONNECT.map((val, i) => (
                                                    <li key={`connect-${i}`}>
                                                        <a onClick={() => {
                                                            analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_CONNECT_CLICK, { content_type: val.label })
                                                        }} href={val.link}>{val.label}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                            {/* <ul> */}
                                        </div>
                                    </div>

                                    <div className='col-lg-2 col-md-4'>
                                        <div className='footer-links'>
                                            <h2>services</h2>
                                            <ul>
                                                {SERVICES.map((val, i) => (
                                                    <li key={`services-${i}`}>
                                                        <Link onClick={() => {
                                                            analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_SERVICE_CLICK, { content_type: val.label })
                                                        }} to={val.link}>{val.label}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='footer-copyright'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <div className='footer-copyright-text'>
                                            <p>
                                                Copyright © 2024 codingworkx. All rights reserved.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='footer-policy-links'>
                                            <ul>
                                                <li>
                                                    <a onClick={() => {
                                                        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_TERMS_CLICK)
                                                    }} href='#'>terms of service</a>
                                                </li>
                                                <li onClick={() => {
                                                    analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.FOOTER_GO_TO_TOP_CLICK)
                                                }} className='highlighted'>
                                                    <a href='#top'>go to top</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
