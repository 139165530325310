import { services_all } from "../../utils/constants";
import { ServiceCard } from "./ServiceCard";
import { ServiceHeading } from "./ServiceHeading";
import "./services.css";

const Services = () => {
    return (
        <div>
            <div className='our-services'>
                <div className='container'>
                    <ServiceHeading />
                    <div className='row'>
                        {services_all.map((value) => (
                            <div key={value.heading} className='col-lg-4 col-md-4'>
                                <ServiceCard
                                    heading={value.heading}
                                    description={value.description}
                                    img={value.img}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
