import { ContactForm } from "../ContactForm/ContactForm";

export const ContactFormSection = () => {
    return (
        <div>
            <div className='contact-us'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <div className='contact-details'>
                                <div className='section-title'>
                                    <h3 className='wow fadeInUp'>contact us</h3>
                                    <h2 className='text-anime-style-3'>
                                        Get in touch with us today
                                    </h2>
                                </div>

                                <div className='contact-detail-body'>
                                    <h3 className='wow fadeInUp' data-wow-delay='0.5s'>
                                        follow us:
                                    </h3>
                                    <ul className='wow fadeInUp' data-wow-delay='0.75s'>
                                        <li>
                                            <a href='https://linkedin.com/company/codingworkx/'>
                                                <i className='fa-brands fa-linkedin-in'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://www.facebook.com/people/Codingworkx/61561113533536/'>
                                                <i className='fa-brands fa-facebook-f'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://www.instagram.com/codingworkx?igsh=bWF6ZXBmNzhwdWph'>
                                                <i className='fa-brands fa-instagram'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='contact-form-box wow fadeInUp' data-wow-delay='0.5s'>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
