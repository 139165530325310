import { Link } from "react-router-dom";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";
interface Props {
    heading: string;
    breadcrumb: {
        label: string;
        link?: string;
    }[];
}
const CommonBanner = ({ heading, breadcrumb }: Props) => {
    return (
        <div className='page-header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='page-header-box'>
                            <h1 className='text-anime-style-3'>{heading}</h1>
                            <nav className='wow fadeInUp' data-wow-delay='0.25s'>
                                <ol className='breadcrumb'>
                                    {breadcrumb.map((value, i) =>
                                        value.link ? (
                                            <li key={`breadcrumb-${i}`} className='breadcrumb-item'>
                                                <Link onClick={() => {
                                                    analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.SECTION_CLICK, { content_type: heading })
                                                }} to={value.link}>{value.label}</Link>
                                            </li>
                                        ) : (
                                            <li key={`breadcrumb-${i}`}
                                                className='breadcrumb-item active'
                                                aria-current='page'>
                                                {value.label}
                                            </li>
                                        )
                                    )}
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CommonBanner;
