import React from 'react'
import FirebaseAnalytics from './analytics';
import { analyticRef } from './utils/constants';
import { Provider as RouteProvider } from "./router";

function App() {
    return (
        <div>
            <RouteProvider />
            <FirebaseAnalytics ref={analyticRef} />
        </div>
    );
}

export default App;
