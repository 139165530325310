import { Link } from "react-router-dom";
import { ROUTES } from "../../router/route-constant";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";
// Need to makeComponent
const ChooseUs = () => {
    return (
        <div>
            <div className='why-choose-us'>
                <div className='container'>
                    <div className='row section-row align-items-center'>
                        <div className='col-lg-8 col-md-12'>
                            <div className='section-title'>
                                <h3 className='wow fadeInUp'>why choose us</h3>
                                <h2 className='text-anime-style-3'>Why choose us ?</h2>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div className='why-choose-us-item wow fadeInUp' data-wow-delay='0.25s'>
                                <div className='icon-box'>
                                    <img src={"./images/icon-whyus-1.svg"} alt='' />
                                </div>
                                <h3>Full-cycle custom software development</h3>
                                <p>
                                    From ideation to deployment, our professionals are here for you
                                    right from the beginning. We help in crafting tailored software
                                    solutions to meet unique needs, encompassing design, and
                                    development phases.
                                </p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='why-choose-us-item wow fadeInUp' data-wow-delay='0.5s'>
                                <div className='icon-box'>
                                    <img src={"./images/icon-whyus-2.svg"} alt='' />
                                </div>
                                <h3>Mobile-first design for consistent user experience</h3>
                                <p>
                                    This ensures a consistent user experience by prioritizing mobile
                                    optimization, enhancing accessibility, and streamlining
                                    functionality across all devices.
                                </p>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='why-choose-us-item wow fadeInUp' data-wow-delay='0.75s'>
                                <div className='icon-box'>
                                    <img src={"./images/icon-whyus-3.svg"} alt='' />
                                </div>
                                <h3>Agile principles for quick Return on Investment</h3>
                                <p>
                                    Agile principles boost ROI by emphasizing rapid iterations,
                                    customer feedback, adaptive planning, continuous delivery, and
                                    prioritizing high-value features.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='why-us-explore-item'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='why-us-section-title'>
                                            <div className='section-title'>
                                                <h2 className='text-anime-style-3'>
                                                    Check Out Our Expertise
                                                </h2>
                                            </div>

                                            <div className='explore-item-icon'>
                                                <img src={"./images/icon-whyus-4.svg"} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <div
                                            className='explore-item-content wow fadeInUp'
                                            data-wow-delay='0.25s'>
                                            <p>
                                                CodingWorkX is a tech company specializing in
                                                mobile and website solutions. They provide
                                                innovative, user-centric designs and robust
                                                development services. Their expertise spans across
                                                various industries, ensuring tailored, scalable, and
                                                efficient digital products. Committed to excellence,
                                                CodingWorkX enhances user experience and drives
                                                business growth through cutting-edge technology and
                                                creative solutions.
                                            </p>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div
                                            className='explore-item-tags wow fadeInUp'
                                            data-wow-delay='0.25s'>
                                            <ul>
                                                <li>
                                                    <a
                                                        onClick={() => {
                                                            analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_LINKEDIN_CLICK)
                                                        }}
                                                        href='https://linkedin.com/company/codingworkx/'
                                                        className='btn-default'>
                                                        linkedin
                                                    </a>
                                                </li>
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_CONTACT_US_CLICK)
                                                        }}
                                                        to={ROUTES.CONTACT}
                                                        className='btn-default'>
                                                        contact Us
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseUs;
