import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface ContactCardProps {
    link: string;
    name: string;
    content: string | ReactNode;
    img: string;
    iconImg: string;
}
export const ContactCard = ({ link, name, content, img, iconImg }: ContactCardProps) => {
    return (
        <div className='contact-item wow fadeInUp' data-wow-delay='0.25s'>
            <div className='contact-content'>
                <div className='contact-content-title'>
                    <h2>{name}</h2>
                    <Link to={link}>
                        <img src={iconImg} alt='' />
                    </Link>
                </div>
                <div>{content}</div>
            </div>
            <div className='contact-image'>
                <figure className='image-anime'>
                    <img src={img} alt='' />
                </figure>
            </div>
        </div>
    );
};
