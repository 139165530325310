import {
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import { RouteConfig, routerConfiguration } from "./routeConfiguration";
import { PublicRoute } from "./PublicRoute";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import { Suspense } from "react";
import Loader from "../components/Loader";
import NotFound from "../modules/NotFound";

export const Provider = () => {
    return (
        <RouterProvider
            router={createBrowserRouter(
                createRoutesFromElements(
                    <Route
                        element={
                            <Suspense fallback={<Loader />}>
                                <Header />
                                <Outlet />
                                <Footer />
                            </Suspense>
                        }>
                        {routerConfiguration.map((route: RouteConfig) => {
                            let RouteWrapper = PublicRoute;
                            return (
                                <Route
                                    key={route.id}
                                    path={route.path}
                                    element={<RouteWrapper {...route} />}
                                />
                            );
                        })}
                        <Route path="*" element={<NotFound />} />
                    </Route>
                )
            )}
        />
    );
};
