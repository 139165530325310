const Technologies = () => {
    return (
        <div className="exclusive-partners">
            <div className="container">
                <div className="row section-row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="wow fadeInUp">new age technologies</h3>
                            <h2 className="text-anime-style-3">for world-class solutions</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="0.2s">
                            <img src="images/icon-tech-1.webp" alt="" />
                            <span>{"React Native"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="0.4s">
                            <img src="images/icon-tech-2.webp" alt="" />
                            <span>{"Flutter"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="0.6s">
                            <img src="images/icon-tech-3.png" alt="" />
                            <span>{"Swift UI"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="0.8s">
                            <img src="images/icon-tech-4.png" alt="" />
                            <span>{"Kotlin"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
                            <img src="images/icon-tech-8.png" alt="" />
                            <span>{"Java"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1s">
                            <img src="images/icon-tech-5.png" alt="" />
                            <span>{"Node.js"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
                            <img src="images/icon-tech-12.png" alt="" />
                            <span>{"Firebase"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1s">
                            <img src="images/icon-tech-9.png" alt="" />
                            <span>{"Gatsby"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.2s">
                            <img src="images/icon-tech-6.webp" alt="" />
                            <span>{"Graph QL"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.4s">
                            <img src="images/icon-tech-7.webp" alt="" />
                            <span>{"AWS Cloud"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.2s">
                            <img src="images/icon-tech-10.png" alt="" />
                            <span>{"Mongo DB"}</span>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className="partners-logo wow fadeInUp" data-wow-delay="1.4s">
                            <img src="images/icon-tech-11.png" alt="" />
                            <span>{"Postgres"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Technologies;