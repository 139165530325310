export const ROUTES = {
  HOME: "/",
  FAQ: "/faq",
  ABOUT: "/about-us",
  PORTFOLIO: "/portfolio",
  SERVICES: "/services",
  CONTACT: "/contact-us",
};

export interface LinkItem {
  label: string;
  link: string;
}
