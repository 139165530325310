const SectionHeading = () => {
    return (
        <div>
            <div className='page-about'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <div className='page-about-image'>
                                <div className='about-img-1'>
                                    <figure className='image-anime reveal'>
                                        <img src={"./images/page-about-1.jpg"} alt='' />
                                    </figure>
                                </div>
                                <div className='about-img-2'>
                                    <figure className='image-anime reveal'>
                                        <img
                                            src={"./images/logo.svg"}
                                            alt=''
                                            style={{
                                                backgroundColor: "#fff",
                                            }}
                                        />
                                    </figure>
                                </div>
                                <div className='work-experience'>
                                    <div className='work-experience-icon'>
                                        <img src={"./images/icon-work-experience.svg"} alt='' />
                                    </div>
                                    <div className='work-experience-content'>
                                        <h3>
                                            <span className='counter'>8</span>+
                                        </h3>
                                        <p>Work Experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='about-content'>
                                <div className='section-title'>
                                    <h3 className='wow fadeInUp'>about us</h3>
                                    <h2 className='text-anime-style-3'>CodingWorkX</h2>
                                </div>

                                <div className='wow fadeInUp' data-wow-delay='0.25s'>
                                    <p className='wow fadeInUp' data-wow-delay='0.5s'>
                                        At CodingWorkX, we specialize in offering scalable web and mobile applications to our clients that cater to their businesses. We have a team of professionals that are tailored to meet the unique needs of businesses across diverse industries. Our team of skilled developers and designers combine their innovation and 8+ years of expertise to deliver solutions that drive growth and success.
                                    </p>
                                </div>
                                <p className='wow fadeInUp' data-wow-delay='0.25s'>
                                    From concept to deployment, we prioritize quality, efficiency, and client satisfaction over everything else.  Our team always believes in the power of technology to make a successful business and drive constant growth. We always prefer to keep our approach highly collaborative and client-centric because this helps us to know your requirements better and work for it closely with you. It helps us to ensure that every project exceeds expectations. Why choose us?
                                </p>

                                <ul className='wow fadeInUp' data-wow-delay='1s'>
                                    <li>Innovative Thinking</li>
                                    <li>3+ years of experience</li>
                                    <li>ML Ops</li>
                                    <li>AI chatbots</li>
                                    <li>Conversational AI</li>
                                    <li>Hybrid app development</li>
                                    <li>Blockchain development</li>
                                    <li>Generative AI solutions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionHeading;
