import { useEffect } from "react";
import ChooseUs from "../../components/ChooseUs";
import CommonBanner from "../../components/CommonBanner";
import SectionHeading from "../../components/SectionHeading";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";
import StatsThatMatter from "../../components/StatsThatMatter";
import MeetTheExperts from "../../components/MeetTheExperts";
import Testimonials from "../../components/Testimonials";
import Technologies from "../../components/Technologies";
import ScrollingServices from "../../components/ScrollingServices";

const BREADCRUMBS: {
    label: string;
    link?: string;
}[] = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "About us",
        },
    ];

export const AboutPage = () => {
    useEffect(() => {
        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.ABOUT_PAGE_LOADED)
    }, [])
    return (
        <div>
            <CommonBanner breadcrumb={BREADCRUMBS} heading='About Us' />
            <SectionHeading />
            <ChooseUs />
            <Technologies />
            <StatsThatMatter />
            <MeetTheExperts />
            <ScrollingServices />
            <Testimonials />
        </div>
    );
};

export default AboutPage;
