
const Loader = () => {
    return (
        <div className='loading-container'>
            <div className='loading'></div>
            <div id='loading-icon'>
                <img src={"./images/logo.svg"} alt='' />
            </div>
        </div>
    );
};
export default Loader;
