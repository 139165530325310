const ScrollingServices = () => {
    return (
        <div className="scrolling-ticker">
            <div className="scrolling-ticker-box">
                <div className="scrolling-content">
                    <span><i className="fa-solid fa-circle"></i>App Development</span>
                    <span><i className="fa-solid fa-circle"></i>Web Development</span>
                    <span><i className="fa-solid fa-circle"></i>Graphics Design</span>
                    <span><i className="fa-solid fa-circle"></i>Digital Marketing</span>
                    <span><i className="fa-solid fa-circle"></i>Generative AI Solutions</span>
                    <span><i className="fa-solid fa-circle"></i>AI Chatbots</span>
                    <span><i className="fa-solid fa-circle"></i>Blockchain Development</span>
                </div>

                <div className="scrolling-content">
                    <span><i className="fa-solid fa-circle"></i>App Development</span>
                    <span><i className="fa-solid fa-circle"></i>Web Development</span>
                    <span><i className="fa-solid fa-circle"></i>Graphics Design</span>
                    <span><i className="fa-solid fa-circle"></i>Digital Marketing</span>
                    <span><i className="fa-solid fa-circle"></i>Generative AI Solutions</span>
                    <span><i className="fa-solid fa-circle"></i>AI Chatbots</span>
                    <span><i className="fa-solid fa-circle"></i>Blockchain Development</span>
                </div>
            </div>
        </div>
    );
}

export default ScrollingServices;