import React from "react";

// types
export type ServiceType = {
  heading: string;
  description: string;
  img: string;
};

export type TestimonialType = {
  name: string;
  title: string;
  remarks: string;
};

// types
export const services: ServiceType[] = [
  {
    heading: "Mobile App Development",
    description: `We create high-quality mobile apps that meet client needs and ensure great user experiences across devices.`,
    img: "./images/service-img-4.jpg",
  },
  {
    heading: "web development",
    description: `We deliver captivating digital experiences through sleek, responsive websites that reflect your vision and creativity.`,
    img: "./images/service-img-1.jpg",
  },
  {
    heading: "Digital Marketing",
    description: `CodingWorkX boosts your online presence with effective strategies that drive traffic, engage audiences, and maximize ROI.`,
    img: "./images/service-img-2.jpg",
  },
  {
    heading: "Graphics Design",
    description: `Our designers craft visually stunning masterpieces that elevate your brand identity, ensuring every pixel speaks volumes.`,
    img: "./images/service-img-6.jpg",
  },
  {
    heading: "AI & ML",
    description: `CodingWorkX offers cutting-edge AI/ML services to help businesses harness transformative technologies, enabling data-driven decisions and exceptional experiences.`,
    img: "./images/service-img-7.jpg",
  },
  {
    heading: "Generative AI and chatbots",
    description: `Generative AI chatbots enhance human-AI interaction, boosting productivity and transforming customer experiences across industries.`,
    img: "./images/service-img-8.jpg",
  },
];

export const services_all: ServiceType[] = [
  {
    heading: "Mobile App Development",
    description: `We create high-quality mobile apps that meet client needs and ensure great user experiences across devices.`,
    img: "./images/service-img-4.jpg",
  },
  {
    heading: "Web development",
    description: `We deliver captivating digital experiences through sleek, responsive websites that reflect your vision and creativity.`,
    img: "./images/service-img-1.jpg",
  },
  {
    heading: "Digital Marketing",
    description: `CodingWorkX boosts your online presence with effective strategies that drive traffic, engage audiences, and maximize ROI.`,
    img: "./images/service-img-2.jpg",
  },
  {
    heading: "Graphics Design",
    description: `Our designers craft visually stunning masterpieces that elevate your brand identity, ensuring every pixel speaks volumes.`,
    img: "./images/service-img-6.jpg",
  },
  {
    heading: "AI & ML",
    description: `CodingWorkX offers cutting-edge AI/ML services to help businesses harness transformative technologies, enabling data-driven decisions and exceptional experiences.`,
    img: "./images/service-img-7.jpg",
  },
  {
    heading: "Generative AI and chatbots",
    description: `Generative AI chatbots enhance human-AI interaction, boosting productivity and transforming customer experiences across industries.`,
    img: "./images/service-img-8.jpg",
  },
];

export const testimonials_data: TestimonialType[] = [
  {
    name: "Vishnu Chaudhary",
    title: "Product Manager",
    remarks:
      "Working with CodingWorkX has transformed our healthcare business. They developed applications that improved operational efficiency and patient care. Their team showed exceptional expertise and understanding of our needs.",
  },
  {
    name: "Mazen M.",
    title: "Product Owner",
    remarks:
      "Their team's deep understanding of technology and commitment to innovation turned our vision into a seamless product. They anticipated our needs, delivering a solution that blends functionality with an engaging user experience.",
  },
  {
    name: "Mathis Weil",
    title: "Product Owner",
    remarks:
      "The CodingWorkX expert development team took our solid app and elevated it to new heights. Thanks to their strategic enhancements, user engagement surged by 10%, reflecting their deep understanding of what drives user interaction",
  },
  {
    name: "Rifat A.",
    title: "Product Owner",
    remarks:
      "Partnering with CodingWorkX transformed our mobile application beyond expectations. Their team enhanced our platform, integrating innovative features and optimizing performance while showing commitment to our needs throughout.",
  },
  {
    name: "TecTeams",
    title: "Product Agency",
    remarks:
      "They delivered a seamless integration of a mobile app and website, showcasing their expertise in product development. Their innovative approach and attention to detail resulted in a product that enhanced client engagement and efficiency.",
  },
];

export const analyticRef: any = React.createRef();
