import { testimonials_data } from "../../utils/constants";

// Need to makeComponent
const Testimonials = () => {
    return (
        <div className="clients-testimonials">
            <div className="container">
                <div className="row section-row align-items-center">
                    <div className="col-lg-8 col-md-12">
                        <div className="section-title">
                            <h3 className="wow fadeInUp">client testimonials</h3>
                            <h2 className="text-anime-style-3">Our customers love us.</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="testimonial-slider">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    {
                                        testimonials_data.map((item, i) => {
                                            return (
                                                <div className="swiper-slide" key={`testimonial-${i}`}>
                                                    <div className="testimonial-item">
                                                        <div className="testimonial-rating">
                                                            <img src="images/icon-star.svg" alt="" />
                                                        </div>

                                                        <div className="testimonial-content">
                                                            <p>{item.remarks}</p>
                                                        </div>

                                                        <div className="testimonial-body">
                                                            <div className="testimonial-author-title">
                                                                <h2>{item.name}</h2>
                                                                <p>{item.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
