import React from 'react';
import About from "./components/About";
import Banner from "./components/Banner";
import ChooseUs from "../../components/ChooseUs";
import Services from "../../components/Services";
import { analyticRef } from '../../utils/constants';
import { FIREBASE_EVENT } from '../../utils/Model';
import Testimonials from '../../components/Testimonials';

const Home = () => {
    React.useEffect(() => {
        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_PAGE_LOADED)
    }, [])
    return (
        <div>
            <Banner />
            <About />
            <Services />
            <ChooseUs />
            <Testimonials />
        </div>
    );
};
export default Home;
