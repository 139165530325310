import { useEffect } from "react";
import CommonBanner from "../../components/CommonBanner";
import { ServiceCard } from "../../components/Services/ServiceCard";
import { analyticRef, services_all } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";
import StatsThatMatter from "../../components/StatsThatMatter";
import Technologies from "../../components/Technologies";
import ChooseUs from "../../components/ChooseUs";
import Testimonials from "../../components/Testimonials";

const BREADCRUMBS: {
    label: string;
    link?: string;
}[] = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "services",
        },
    ];

const ServicesPage = () => {

    useEffect(() => {
        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.SERVICE_PAGE_LOADED)
    }, [])

    return (
        <div>
            <CommonBanner heading='Services' breadcrumb={BREADCRUMBS} />
            <div className='our-services page-service'>
                <div className='container'>
                    <div className='row'>
                        {services_all.map((value) => (
                            <div key={value.heading} className='col-lg-4 col-md-4'>
                                <ServiceCard
                                    heading={value.heading}
                                    description={value.description}
                                    img={value.img}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <StatsThatMatter />
            <Technologies />
            <ChooseUs />
            <Testimonials />
        </div>
    );
};

export default ServicesPage;
