const MeetTheExperts = () => {
    return (
        <div className="our-team">
            <div className="container">
                <div className="row section-row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="wow fadeInUp">our team</h3>
                            <h2 className="text-anime-style-3">Meet the experts</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 margin-right-20">
                        <div className="team-member-item wow fadeInUp" data-wow-delay="0.25s">
                            <div className="team-image">
                                <figure>
                                    <img src="images/team-1.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="team-body">
                                <div className="team-content">
                                    <h3>Vijay Sharma</h3>
                                    <p>Founder & CEO</p>
                                </div>
                                <div className="team-icon">
                                    <a href="https://www.linkedin.com/in/vijay-sharma-80a913112/" target="_blank" rel='noreferrer'><img src="images/icon-share.svg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="team-member-item wow fadeInUp" data-wow-delay="0.25s">
                            <div className="team-image">
                                <figure>
                                    <img src="images/team-2.jpg" alt="" />
                                </figure>
                            </div>
                            <div className="team-body">
                                <div className="team-content">
                                    <h3>Nitish Kuar</h3>
                                    <p>Co-Founder & CTO</p>
                                </div>
                                <div className="team-icon">
                                    <a href="https://www.linkedin.com/in/nitish-kumar-45a231154/" target="_blank" rel='noreferrer'><img src="images/icon-share.svg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeetTheExperts;
