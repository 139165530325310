import "./services.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../router/route-constant";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";

interface Props {
    heading: string;
    description: string;
    img: string;
}

export const ServiceCard = ({ heading, description, img }: Props) => {
    return (
        <div className='service-item wow fadeInUp' data-wow-delay='0.25s'>
            <div className='service-content'>
                <div className='service-content-title'>
                    <h2>{heading}</h2>
                    <Link onClick={() => {
                        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_SERVICE_CLICK, { content_type: heading })
                    }} to={ROUTES.SERVICES}>
                        <img src={"./images/arrow.svg"} alt='' />
                    </Link>
                </div>
                <div className='service-image'>
                    <figure className='image-anime'>
                        <img src={img || "./images/service-img-1.jpg"} alt='' />
                    </figure>
                </div>
                <p>{description}</p>
            </div>
        </div>
    );
};
