const StatsThatMatter = () => {
    return (
        <div className="overview-company">
            <div className="container">
                <div className="row section-row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="wow fadeInUp">ourview comapny</h3>
                            <h2 className="text-anime-style-3">Stats that matter</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="counter-box">
                            <h3><span className="counter">50</span><em>+</em></h3>
                            <p>Global Clients</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="counter-box">
                            <h3><span className="counter">10</span><em>+</em></h3>
                            <p>IT Services</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="counter-box">
                            <h3><span className="counter">3</span><em>+</em> years</h3>
                            <p>In Development</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="counter-box">
                            <h3><em>$</em><span className="counter">500</span><em>+</em> K</h3>
                            <p>Total Revenue</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsThatMatter;
