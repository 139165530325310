export enum FIREBASE_EVENT {
    HOME_PAGE_LOADED = 'homePageLoaded',
    HOME_SERVICE_CLICK = 'homeServiceClick',
    HOME_LINKEDIN_CLICK = 'homeLinkedInClick',
    FOOTER_PAGE_CLICK = 'footerPageClick',
    FOOTER_EMAIL_CLICK = 'footerEmailClick',
    FOOTER_PHONE_CLICK = 'footerEmailClick',
    FOOTER_CONNECT_CLICK = 'footerConnectClick',
    FOOTER_TERMS_CLICK = 'footerTermsClick',
    FOOTER_GO_TO_TOP_CLICK = 'footerGoToTopClick',
    SECTION_CLICK = 'sectionClick',
    SERVICE_PAGE_LOADED = 'servicePageLoaded',
    ABOUT_PAGE_LOADED = 'aboutPageLoaded',
    FOOTER_SERVICE_CLICK = 'footerServiceClick',
    HOME_CONTACT_US_CLICK = 'homeContactUsClick',
    HOME_VIEW_ALL_SERVICE_CLICK = 'homeViewAllServiceClick',
    HOME_FREE_CONSULTANT_CLICK = 'homeFreeConsultantClick',
    PAGE_NOT_FOUND = 'pageNotFound',
    FAQ_PAGE_LOADED = 'faqPageLoaded',
}


