import { Link } from "react-router-dom";
import { ROUTES } from "../../../../router/route-constant";
import { analyticRef } from "../../../../utils/constants";
import { FIREBASE_EVENT } from "../../../../utils/Model";
const About = () => {
    return (
        <div>
            <div className='about-us'>
                <div className='container'>
                    <div className='row section-row align-items-center'>
                        <div className='col-lg-8'>
                            <div className='section-title'>
                                <h3 className='wow fadeInUp'>about Company</h3>
                                <h2 className='text-anime-style-3'>
                                    Solutions that tell your brand's story
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <div className='about-image'>
                                <div className='about-img'>
                                    <figure className='image-anime reveal'>
                                        <img src={"./images/about-us-img.jpg"} alt='' />
                                    </figure>
                                </div>
                                <div className='about-consultation'>
                                    <figure>
                                        <img src={"./images/about-circle.png"} alt='' />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='about-content'>
                                <p className='wow fadeInUp' data-wow-delay='0.25s'>
                                    Join forces with our team of seasoned experts, boasting over 8
                                    years of combined experience in crafting innovative solutions.
                                    From concept to deployment, we're committed to delivering
                                    exceptional quality, efficiency, and client satisfaction. Our
                                    relentless pursuit of excellence ensures that every project
                                    exceeds expectations. What sets us apart?
                                </p>
                                <ul className='wow fadeInUp' data-wow-delay='1s'>
                                    <li>Proven Track Record</li>
                                    <li>Client-Focused Mindset</li>
                                    <li>Innovative Thinking</li>
                                    <li>3+ years of experience</li>
                                    <li>Flexible and cost-effective solutions</li>
                                </ul>

                                <Link
                                    onClick={() => {
                                        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_FREE_CONSULTANT_CLICK, { content_type: 'homeAboutCompany' })
                                    }}
                                    to={ROUTES.CONTACT}
                                    className='btn-default wow fadeInUp'
                                    data-wow-delay='1.25s'>
                                    free consultation
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
