import CommonBanner from "../../components/CommonBanner";
import { ContactCard, ContactCardProps } from "../../components/ContactCard";
import { ContactFormSection } from "./components/ContactFormSection/ContactFormSection";

const BREADCRUMBS: {
    label: string;
    link?: string;
}[] = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "contact us",
        },
    ];

const CONTACT_CARDS: ContactCardProps[] = [
    {
        name: "Address",
        link: "#",
        content: "312 Ithum Tower C Plot A-40 Sector 62 Noida - 201301",
        img: "./images/contact-info-1.jpg",
        iconImg: "./images/icon-location.svg",
    },
    {
        name: "Call now",
        link: "#",
        content: (
            <>
                <p>(+91) 894 199 9257</p>
                <p>(+91) 826 685 8668</p>
            </>
        ),
        img: "./images/contact-info-2.jpg",
        iconImg: "./images/icon-phone.svg",
    },
    {
        name: "Email us",
        link: "#",
        content: (
            <>
                <p>connect@codingworkx.com</p>
                <p>vijay@codingworkx.com</p>
            </>
        ),
        img: "./images/contact-info-3.jpg",
        iconImg: "./images/icon-mail.svg",
    },
];
const ContactUsPage = () => {
    return (
        <div>
            <CommonBanner breadcrumb={BREADCRUMBS} heading='Contact us' />
            <div className='contact-information'>
                <div className='container'>
                    <div className='row'>
                        {CONTACT_CARDS.map((value, index) => (
                            <div className='col-md-4' key={`contact-item-${index}`}>
                                <ContactCard {...value} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ContactFormSection />
        </div>
    );
};

export default ContactUsPage;
