import { Link } from "react-router-dom";
import { ROUTES } from "../../../../router/route-constant";
import { analyticRef } from "../../../../utils/constants";
import { FIREBASE_EVENT } from "../../../../utils/Model";

const Banner = () => {
    return (
        <div className='hero'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-12'>
                        <div className='hero-layout-2'>
                            <div className='hero-content'>
                                <div className='section-title'>
                                    <h3 className='wow fadeInUp'>CodingWorkX</h3>
                                    <h1 className='text-anime-style-3'>
                                        Your <span>Trusted</span> Business Partner
                                    </h1>
                                </div>

                                <div className='hero-body'>
                                    <p className='wow fadeInUp' data-wow-delay='0.5s'>
                                        At CodingWorkX, we empower businesses to thrive in the
                                        digital landscape by crafting innovative solutions that
                                        drive growth and success. Our team of expert professionals
                                        is dedicated to understanding the unique needs of businesses
                                        across various industries, providing tailored IT services
                                        that meet their specific requirements.
                                    </p>
                                </div>

                                <div className='hero-footer'>
                                    <Link
                                        to={ROUTES.CONTACT}
                                        onClick={() => analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.HOME_FREE_CONSULTANT_CLICK, { content_type: 'homeBanner', })}
                                        className='btn-default wow fadeInUp'
                                        data-wow-delay='0.75s'>
                                        free consultation
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Banner;
