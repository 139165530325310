import { useEffect } from "react";
import CommonBanner from "../../components/CommonBanner";
import { analyticRef } from "../../utils/constants";
import { FIREBASE_EVENT } from "../../utils/Model";

const BREADCRUMBS: {
    label: string;
    link?: string;
}[] = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "404 Error",
        },
    ];

export const NotFound = () => {

    useEffect(() => {
        analyticRef?.current?.logFirebaseEvent(FIREBASE_EVENT.PAGE_NOT_FOUND)
    }, [])

    return (
        <div>
            <CommonBanner breadcrumb={BREADCRUMBS} heading='Page Not Found!' />
            <div className="error-page">
                <div className="container">
                    <div className="row">
                        <div className="error-page-image wow fadeInUp" data-wow-delay="0.25s">
                            <img src="images/404-error-img.png" alt="" />
                        </div>
                        <div className="error-page-content">
                            <div className="error-page-content-heading">
                                <h2 className="text-anime-style-3">Page Not Found!</h2>
                                <p className="wow fadeInUp" data-wow-delay="0.5s">We're sorry, the page you requested could not be found.</p>
                            </div>
                            <a className="btn-default wow fadeInUp" data-wow-delay="0.75s" href="/">Back To Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
