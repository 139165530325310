import React from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    measurementId: "G-DW35ZFJY8D",
    projectId: "codingworkx-f9e57",
    messagingSenderId: "765484007280",
    storageBucket: "codingworkx-f9e57.appspot.com",
    authDomain: "codingworkx-f9e57.firebaseapp.com",
    apiKey: "AIzaSyCn_gCyBEWEcTcSBRCWl1mWFbnCcPUepVA",
    appId: "1:765484007280:web:c123189a31b70d306259b5",
};


const FirebaseAnalytics = React.forwardRef((props: any, ref: any) => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const analyticsRef = React.useRef(analytics)

    React.useImperativeHandle(ref, () => {
        return {
            logFirebaseEvent: (eventName: string, payload: any) => {
                if (analyticsRef?.current) {
                    logEvent(analyticsRef?.current, eventName, payload)
                } else {
                    const analytics = getAnalytics(app);
                    analyticsRef.current = analytics
                    logEvent(analyticsRef?.current, eventName, payload)
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null
})

export default React.memo(FirebaseAnalytics)
